import _ from 'lodash';

export default class Header {
  constructor() {
    this.initIllu();
    this.handleScroll();

    document.addEventListener(
      'scroll',
      _.throttle(this.handleScroll.bind(this), 30)
    );
  }
  initIllu() {
    this.illu = [
      {
        el: document.querySelector('.illu-layer--wegweiser'),
        speed: 0,
      },
      {
        el: document.querySelector('.illu-layer--front'),
        speed: 5,
      },
      {
        el: document.querySelector('.illu-layer--berge'),
        speed: 15,
      },
      {
        el: document.querySelector('.illu-layer--stadt'),
        speed: 10,
      },
      {
        el: document.querySelector('.illu-layer--sonne'),
        speed: -30,
      },
    ];
  }
  handleScroll() {
    let rect = this.illu[0].el.getBoundingClientRect();
    let illuPosY = rect.bottom - window.innerHeight;

    this.illu.forEach(({ el, speed }) => {
      let posY = illuPosY * (speed / 80) * -1;
      if (illuPosY > 0 || window.innerWidth < 500) posY = 0;
      el.style.transform = 'translateY(' + posY + 'px)';
    });
  }
}
