export default class Filter {
  constructor() {
    this.filterEl = document.querySelector('.c-filter');

    this.initFilters();
  }
  initFilters() {
    this.selects = document.querySelectorAll('.js-filterSelect');
    this.searchBtn = document.querySelector('.js-searchBtn');
    this.searchInput = document.querySelector('.js-searchInput');

    for (let i = 0; i < this.selects.length; i++) {
      this.selects[i].addEventListener('change', (el) => {
        this.setFilters();
      });
    }

    this.searchBtn.addEventListener('click', this.setFilters.bind(this));

    this.searchInput.addEventListener('keypress', (e) => {
      let key = e.which || e.keyCode;
      if (key == 13) this.setFilters();
    });

    if (this.searchInput.value != '') {
      this.searchInput.focus();
    }
  }
  setFilters() {
    let params = {};

    for (let i = 0; i < this.selects.length; i++) {
      let el = this.selects[i];

      if (el.selectedIndex != 0) {
        params[el.dataset.cat] = el.options[el.selectedIndex].value;
      }
    }

    let query = this.searchInput.value;
    if (query) {
      params['q'] = query;
    }

    let urlParams = Object.keys(params)
      .map((i) => `${i}=${params[i]}`)
      .join('&');
    let url = this.filterEl.dataset.baseurl + '?' + urlParams;

    window.location.href = url;
  }
}
