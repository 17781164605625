import 'normalize.css';
import '../scss/main.scss';

import Header from './header';
import Home from './home';
import Filter from './filter';

let header = new Header();

if (document.querySelector('.p-home')) {
  let home = new Home();
}
if (document.querySelector('.c-filter')) {
  let filter = new Filter();
}

if (document.querySelector('.overlay')) {
  const overlayCloseBtns = document.querySelectorAll('.js-closeOverlay');
  for (let i = 0; i < overlayCloseBtns.length; i++) {
    overlayCloseBtns[i].addEventListener('click', () => {
      document.querySelector('.overlay').remove();
      localStorage.setItem('wegweiser-overlay', 3);
    });
  }

  if (!localStorage.getItem('wegweiser-overlay')) {
    document.querySelector('.overlay').classList.add('visible');
  }
}
