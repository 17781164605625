export default class Header {
  constructor() {
    this.el = document.querySelector('.c-header');
    this.menuToggle = document.querySelector('.js-toggleMobileMenu');
    this.initMenu();
  }

  initMenu() {
    this.menuToggle.addEventListener('click', this.toggleMobileMenu.bind(this));
  }

  toggleMobileMenu() {
    this.el.classList.toggle('visible');
  }
}
